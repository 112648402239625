import { graphql, useStaticQuery } from 'gatsby'

import { SEO } from '@karakuri-ai/faq-component'

import { Maybe, SettingYaml } from '../graphql'

function toUrl(siteUrl?: Maybe<string>, botId?: Maybe<string>, pathname?: string) {
  const url = siteUrl ?? `https://faq.${botId}.karakuri.ai`
  return `${url}${pathname}`
}

export interface Data {
  settingYaml: SettingYaml
}

const query = graphql`
  query SEO {
    settingYaml(ignore: { ne: true }) {
      title
      description
      url
      headerImageUrl
      language
      twitter
      facebook
      siteUrl
      botId
    }
  }
`
export interface Props {
  title?: string
  description?: string
  banner?: string
  pathname?: string
  disableDefaultDescription?: boolean
}

export function SEOWidget({
  title,
  description,
  banner,
  pathname,
  disableDefaultDescription,
}: Props) {
  const { settingYaml }: Data = useStaticQuery(query)

  const {
    title: defaultTitle,
    description: defaultDescription,
    botId,
    siteUrl,
    headerImageUrl: defaultBanner,
    language,
    twitter,
    facebook,
  } = settingYaml
  return (
    <SEO
      title={title}
      description={description}
      banner={banner}
      defaultTitle={defaultTitle || ''}
      defaultDescription={defaultDescription || ''}
      url={toUrl(siteUrl, botId, pathname)}
      defaultBanner={defaultBanner || ''}
      language={language || ''}
      twitter={twitter || ''}
      facebook={facebook || ''}
      canonical={toUrl(siteUrl, botId, pathname)}
      disableDefaultDescription={disableDefaultDescription}
    />
  )
}
